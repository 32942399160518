import axios from 'axios';

let prefix = "/dashboard"

const DashboardApi = {
	
	getAdminDashboard(payload){
		return axios.get( prefix + "/admin-panel/get", {params: payload})
	},
}

export default DashboardApi;