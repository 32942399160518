import axios from 'axios';

let prefix = "/user-arbitrage-order"

const UserAssetHistoryApi = {
	
	listUserArbitrageOrder(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default UserAssetHistoryApi;