import axios from 'axios';

let prefix = "/wallet-transaction"

const WalletTransactionApi = {
	
	getWalletTransactionList(payload){
		return axios.get( prefix + "/list-admin", {params: payload})
	},

	getWalletTransactionStatusRef(payload){
		return axios.get( prefix + "/transaction-status-ref", {params: payload})
	},

	getWalletTransactionTypeRef(payload){
		return axios.get( prefix + "/transaction-type-ref", {params: payload})
	},

	getWalletTransactionableRef(payload){
		return axios.get( prefix + "/transactionable-ref", {params: payload})
	},

	listWalletTransactionTotal(payload){
		return axios.get( prefix + "/transaction-total", {params: payload})
	},

	listSendToUser(payload){
		return axios.get( prefix + "/list-send-to-user", {params: payload})
	},
}

export default WalletTransactionApi;